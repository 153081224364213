@import "variables";
.text-success{
  color:$text-success !important;
}

.text-danger{
  color:$text-dangerous !important;
}
.primary-btn-modal{
  background-color: $SecondaryColorGreen;
  color: #fff;
  border-color: transparent;
}

.primary-btn-modal:hover, .primary-btn-modal:focus{
  background-color: $SecondaryColorGreenHover;
  color: #fff;
  border-color: transparent;
  box-shadow: none !important;
  outline: none;
}
.movebutton{
  background: #11223B;
}
.movebutton:hover{
  background: #091421!important;
}

.movebutton:focus{
  background: #091421!important;
}

.btn-primary{
  background: #00A7FF;
  border: 1px solid #00A7FF;
}


.secondary-btn-modal{
  background-color: #E8EBEE;
  color: $primaryColor;
  border-color: transparent;
  box-shadow: none;

}
.secondary-btn-modal:focus, .secondary-btn-modal:hover{
  background-color: #E8EBEE;
  color: $primaryColor;
  border-color: transparent;
  box-shadow: none !important;
  outline: none;

}
.btn-light{
  border: 1px solid $greyOutine;
  background: #fff;
  color: $primaryColor;
  box-shadow: none !important;

}
.btn-light:hover, .btn-light:focus, .btn-light:active{
  border: 1px solid $greyOutine;
  background: #fff;
  color: $primaryColor;
  box-shadow: none !important;
  outline: none;
}

//column classes
.col-20{
  flex: 0 0 auto;
  width: 20%;
  padding-inline: 8px;
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1600px) {
  .col-20{
    width: 25%;
    img{
      height: 238px;
  }
  }

}
@media screen and (max-width: 1150px) {
  .col-20{
    width: 33.3%;
    img{
      height: 250px;
  }
  }

}
@media screen and (max-width: 990px) {
  .col-20{
    width: 50%;
      img{
        height: 330px;
      }


  }

}
@media screen and (max-width: 600px) {
  .col-20{
    width: 100%;
  }

}
//alert
.alert-primary {
  color: #008CE3 !important;
  border: 1px solid #008CE3;
  background: #DFF2F7;
  padding: 18px 24px;
    h4{
      margin-bottom: 8px !important;
    }
    p{
      margin: 0;
      color: #008CE3 !important;
      line-height: 24px;
    }
}

