@font-face {
    font-family: 'Red Hat Display ';
    src: url('RedHatDisplay-Regular.woff2') format('woff2'),
        url('RedHatDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-Bold.woff2') format('woff2'),
        url('RedHatDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-Black.woff2') format('woff2'),
        url('RedHatDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-Regular.woff2') format('woff2'),
        url('RedHatDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-BoldItalic.woff2') format('woff2'),
        url('RedHatDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-BoldItalic.woff2') format('woff2'),
        url('RedHatDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-Bold.woff2') format('woff2'),
        url('RedHatDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-BlackItalic.woff2') format('woff2'),
        url('RedHatDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-BlackItalic.woff2') format('woff2'),
        url('RedHatDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-Black.woff2') format('woff2'),
        url('RedHatDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-Italic.woff2') format('woff2'),
        url('RedHatDisplay-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-Medium.woff2') format('woff2'),
        url('RedHatDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-MediumItalic.woff2') format('woff2'),
        url('RedHatDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Regular.woff2') format('woff2'),
        url('RedHatText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-Italic.woff2') format('woff2'),
        url('RedHatDisplay-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-Medium.woff2') format('woff2'),
        url('RedHatDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('RedHatDisplay-MediumItalic.woff2') format('woff2'),
        url('RedHatDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Bold.woff2') format('woff2'),
        url('RedHatText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Bold.woff2') format('woff2'),
        url('RedHatText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Regular.woff2') format('woff2'),
        url('RedHatText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-MediumItalic.woff2') format('woff2'),
        url('RedHatText-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Medium.woff2') format('woff2'),
        url('RedHatText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Medium.woff2') format('woff2'),
        url('RedHatText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Italic.woff2') format('woff2'),
        url('RedHatText-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-BoldItalic.woff2') format('woff2'),
        url('RedHatText-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Italic.woff2') format('woff2'),
        url('RedHatText-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-BoldItalic.woff2') format('woff2'),
        url('RedHatText-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-MediumItalic.woff2') format('woff2'),
        url('RedHatText-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

